.form {
    max-width: 100%;
    margin: auto;
    padding: 20px 0px 20px 35px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgba(220, 229, 255, 1);
    display: flex;
    flex-direction: column;
}

.agreediv {

    color: rgba(34, 64, 155, 1);
    font-size: 22px;
    font-weight: bolder;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column
}

::placeholder {
    color: grey;
    opacity: 0.5;
    font-size: 15px;
    font-weight: 800;
    /* Firefox */
}


.submitrow {
    margin-right: 3%;
}

h4,
h5 {
    margin: 0px;
    color: red;
    font-size: 16px;
}

.submitbutton {
    display: flex;
    justify-content: end;
    align-items: end;
}

.familytable {
    margin-bottom: 15px;
}

.familytable {
    /* border: 2px solid; */
    /* border-radius: 5px; */
    width: 100%;
    text-align: left;
    margin-right: 5%;

}

.addrowButton {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    width: 40%;
    height: 40px;
    background-color: rgba(34, 64, 155, 1);
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 10%;
    font-size: 15px;
}

.removediv {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.starspan {
    color: red;
}

.labelstar {
    color: rgba(34, 64, 155, 1);
    font-size: 22px;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
}

.stardiv {
    font-size: 22px;
    font-weight: bolder;
    display: flex;
    flex-direction: row;
}

.formh1 {
    margin: 20px 0px;
    text-align: center;
    font-size: 50px;
    line-height: 50px;
    color: rgba(34, 64, 155, 1);
}

.formdiv {
    padding: 0px;
}

.nameRow {
    padding-right: 5%;
}

.label {
    color: rgba(34, 64, 155, 1);
    font-size: 22px;
    font-weight: bolder;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
}

.label2 {
    color: rgba(34, 64, 155, 1);
    font-size: 22px;
    margin: 7% 10% 5px 0px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.label3 {
    color: rgba(34, 64, 155, 1);
    font-size: 22px;
    margin-right: 5px;
    margin-bottom: 10px;

}

.formdiv1 {
    display: flex;
    justify-content: space-between;
}

.input {
    max-width: 30%;
    padding: 8px;
    height: 40px;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
    background-color: white;

}

.checkbox {
    width: 2%;
    padding: 0px;
    height: 45px;
    margin: 4px 4px 0px 0px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: white;
}

.buttondiv {
    margin-top: 10px;
    width: 250px;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
}


.uploaddiv {
    color: rgba(34, 64, 155, 1);
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
}

.buttondivupload {
    border-radius: 10px;
    cursor: pointer;
    height: 40px;
    margin-right: 5px;

}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 50px;
    background-color: rgba(34, 64, 155, 1);
    color: white;
    padding: 10px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 10%;
    font-size: 30px;

}

.formdiv1input {
    width: 25%;
    padding: 8px;
    height: 40px;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
    background-color: white;

}

.selectinput {
    border: 2px solid;
}

.formdiv2select {
    width: 35%;
    padding: 8px;
    height: 40px;
    border: 2px solid;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: white;
    margin-bottom: 10px;
}

.formdiv2input {
    width: 90%;
    padding: 8px;
    height: 40px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: white;

    margin-bottom: 10px;
}

.formdiv3input {
    width: 85%;
    height: 40px;
    padding-left: 9px;
    border: 2px solid black;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: white;

    margin-bottom: 10px;
}

.familyinput {
    width: 245px;
    height: 40px;
    padding-left: 9px;
    border: 2px solid black;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: white;

    margin-bottom: 10px;
}

.formdivLaginput {
    width: 100%;
    height: 35px;
    border: 2px solid black;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: white;

    margin-bottom: 10px;
}

.langdiv1 {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
}

.langdiv2 {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
}

.uploadCol {
    display: flex;
    justify-content: space-between;
}

.uploadbuttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 160px;
    margin-top: 10px;
}


@media (max-width: 500px) {
    .agreediv {

        color: rgba(34, 64, 155, 1);
        font-size: 18px;
        font-weight: bolder;
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column
    }

    .buttondiv {
        margin-top: 10px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: end;
    }

    .checkbox {
        width: 7%;
        padding: 0px;
        height: 45px;
        margin: 4px 4px 0px 0px;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: white;
    }

    .addrowButton {

        width: 46%;
        height: 40px;
        background-color: rgba(34, 64, 155, 1);
        color: white;
        border: none;
        border-radius: 15px;
        cursor: pointer;
        margin-bottom: 10px;
        margin-right: 0px;
        font-size: 15px;
    }

    .uploadCol {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .uploadbuttons {
        display: flex;
        justify-content: center;
        gap: 60px;
    }

    .nameRow {
        padding-right: 0px;
    }

    .formh1 {
        margin: 0px 0px;
        text-align: center;
        font-size: 35px;
        line-height: 42px;
        color: rgba(34, 64, 155, 1);
    }

    .formdiv {
        padding: 0px 10px 0px 10px;
    }

    .formdiv1 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .label2 {
        color: rgba(34, 64, 155, 1);
        font-size: 22px;
        margin: 5px 0px 0px 0px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .formdiv2select {
        width: 20%;
        padding: 8px;
        height: 40px;
        border: 2px solid;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: white;
        margin-bottom: 10px;
    }

    .formdiv2input {
        width: 100%;
        padding: 8px;
        height: 40px;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: white;

        margin-bottom: 10px;
    }

    .formdiv3input {
        width: 100%;
        height: 40px;
        border: 2px solid black;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: white;

        margin-bottom: 10px;
    }

    .formdiv1input {
        width: 100%;
        padding: 10px;
        height: 45px;
        border-radius: 5px;
        box-sizing: border-box;
        margin-bottom: 10px;
        background-color: white;

    }



    .form {
        max-width: 100%;
        margin: auto;
        padding: 0px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: rgba(220, 229, 255, 1);
        display: flex;
        flex-direction: column;
    }

    .langdiv1 {
        display: flex;
        margin-top: 10px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .button {
        width: 100%;
        height: 54px;
        background-color: rgba(34, 64, 155, 1);
        color: white;
        padding: 10px;
        border: none;
        border-radius: 15px;
        cursor: pointer;
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-right: 0px;
        font-size: 30px;

    }
}



@media (min-width: 500px) and (max-width:800px) {
    .buttondiv {
        margin-top: 10px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: end;
    }

    .checkbox {
        width: 3%;
        padding: 0px;
        height: 45px;
        margin: 4px 4px 0px 0px;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: white;
    }

    .uploadCol {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .addrowButton {
        width: 40%;
        height: 40px;
        background-color: rgba(34, 64, 155, 1);
        color: white;
        border: none;
        border-radius: 15px;
        cursor: pointer;
        margin-bottom: 10px;
        margin-right: 0px;
        font-size: 15px;
    }

    .uploadbuttons {
        display: flex;
        justify-content: center;
        gap: 60px;
        margin-top: 0px;
    }

    .nameRow {
        padding-right: 0px;
    }

    .formh1 {
        margin: 0px 0px;
        text-align: center;
        font-size: 35px;
        line-height: 42px;
        color: rgba(34, 64, 155, 1);
    }

    .formdiv {
        padding: 0px 10px 0px 10px;
    }

    .formdiv1 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .label2 {
        color: rgba(34, 64, 155, 1);
        font-size: 22px;
        margin: 5px 0px 0px 0px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .formdiv2select {
        width: 20%;
        padding: 8px;
        height: 40px;
        border: 2px solid;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: white;
        margin-bottom: 10px;
    }

    .formdiv2input {
        width: 100%;
        padding: 8px;
        height: 40px;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: white;

        margin-bottom: 10px;
    }

    .formdiv3input {
        width: 100%;
        height: 40px;
        border: 2px solid black;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: white;

        margin-bottom: 10px;
    }

    .formdiv1input {
        width: 100%;
        padding: 10px;
        height: 45px;
        border-radius: 5px;
        box-sizing: border-box;
        margin-bottom: 10px;
        background-color: white;

    }



    .form {
        max-width: 100%;
        margin: auto;
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: rgba(220, 229, 255, 1);
        display: flex;
        flex-direction: column;
    }

    .langdiv1 {
        display: flex;
        margin-top: 10px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .button {
        width: 100%;
        height: 54px;
        background-color: rgba(34, 64, 155, 1);
        color: white;
        padding: 10px;
        border: none;
        border-radius: 15px;
        cursor: pointer;
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-right: 0px;
        font-size: 30px;

    }

}

@media (min-width: 800px) and (max-width:1250px) {
    .uploadCol {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        max-width: 100%;
    }

    .formdiv {
        padding: 0px 10px 0px 10px;
    }
}