body {
  user-select: none;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}



.table-container {
  overflow-x: scroll;
  margin-bottom: 10px;
  border: 1px solid rgba(34, 64, 155, 1);
  margin-right: 5%;
  border-radius: 7px;

}

.goog-te-combo {
  width: 100%;
  height: 35px;
  border: 2px solid black;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: white;

  margin-bottom: 10px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;

}



.responsive-table th,
.responsive-table td {
  border: 1px solid rgba(34, 64, 155, 1);
  padding: 8px;
  text-align: left;
}

th:last-child,
td:last-child {
  position: sticky;
  right: 0;
  background-color: rgba(220, 229, 255, 1);
  border: 1px solid rgba(34, 64, 155, 1);
}

.ant-select-selector {
  padding-left: 0px !important;
}

.ant-select-selection-placeholder {
  color: grey !important;
  opacity: 0.5 !important;
  font-size: 15px !important;
  font-weight: 800 !important;
}

@media (max-width: 500px) {
  .ant-card-body {
    padding: 2px !important;
  }

  .table-container {
    overflow-x: auto;
    margin-bottom: 10px;
    border: 1px solid rgba(34, 64, 155, 1);
    margin-right: 0px;
    border-radius: 7px;

  }

  .fatherRow {
    display: flex;
    flex-direction: column;
  }

  .uploadRow {
    display: flex;
    flex-direction: column;
  }

  .ant-col-12 {
    max-width: 100%;
  }

  .ant-col-8 {
    max-width: 100%;
  }

  .ant-col-16 {
    max-width: 100%;
  }
}

@media (min-width: 500px) and (max-width:800px) {
  .ant-card {
    padding: 2px !important;
  }

  .table-container {
    overflow-x: scroll;
    margin-bottom: 10px;
    border: 1px solid rgba(34, 64, 155, 1);
    margin-right: 0px;
    border-radius: 7px;

  }

  .fatherRow {
    display: flex;
    flex-direction: column;
  }

  .uploadRow {
    display: flex;
    flex-direction: column;
  }

  .ant-col-12 {
    max-width: 100%;
  }

  .ant-col-8 {
    max-width: 100%;
  }

  .ant-col-16 {
    max-width: 100%;
  }
}

@media (min-width: 800px) and (max-width:1250px) {
  .ant-card {
    padding: 2px !important;
  }
}

@media (min-width: 800px) and (max-width:1105px) {
  .uploadRow {
    display: flex;
    flex-direction: column;
  }

  .ant-card {
    padding: 2px !important;
  }
}

@media (min-width: 1250px) and (max-width:1530px) {

  .ant-card {
    padding: 0px 8% !important;
  }
}