.checkmark {
    display: flex;
    width: 100%;
    height: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formh1 {
    margin: 20px 0px;
    text-align: center;
    font-size: 50px;
    line-height: 50px;
    color: rgba(34, 64, 155, 1);
}

.formh2 {
    margin: 20px 0px;
    text-align: center;
    font-size: 35px;
    line-height: 40px;
    color: rgba(34, 64, 155, 1);
}

@media (max-width: 500px) {
    .formh1 {
        text-align: center;
        font-size: 35px;
        margin-top: 60px;
        line-height: 42px;
        color: rgba(34, 64, 155, 1);
    }

    .formh2 {
        text-align: center;
        font-size: 20px;
        margin-top: 30px;
        line-height: 32px;
        color: rgba(34, 64, 155, 1);
    }
}